/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.news-container .read-more').each(function() {
            $(this).on("click",function(e) {e.preventDefault(); });
            var targetDiv = $($(this).attr('data-target'));
            $(this).popover({
                'trigger': 'focus',
                'placement' : 'auto right',
                'html': true,
                'content': function() {
                    return targetDiv.html();
                }
            });
        });
        $("#btn-recent-updates").on("click", function() {
            $("#sidebar").toggleClass("expand");
        });

        $(".frm-case-studies select").on("change",function() {
            var mySelector = "";
            $(".frm-case-studies select").each(function() {
                var myVal = $(this).val();
                if(myVal) {
                    mySelector = mySelector + ".tag-"+$(this).attr("name")+"-"+$(this).val();
                }
            });

            if(mySelector) {
                $(".case-study-block:not("+mySelector+")").hide(200);
                $(".case-study-block"+mySelector).show(200);
            } else {
                $(".case-study-block").hide();
            }
        });

        var thisPage = document.location.href;
        var thisPath = document.location.href.replace(/https?\:\/\/[^\/]+/,'');
        $(".dropdown-menu a[href*='#']").each(function() {
            $(this).attr('href', $(this).attr('href').replace(thisPage,'').replace(thisPath,''));
            $(this).parent().removeClass("active");
        });

        $(".nav").on("mouseleave", ".dropdown.open", function() {
            $(this).removeClass("open");
        });
        $(".nav .dropdown-toggle").on("touchend", function(e) {
            $(this).parent().toggleClass("open");
            e.stopPropagation();
            e.preventDefault();
        });

        $("a[href*='#']:not([href='#']):not([data-toggle])").click(function() {
            if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html,body').animate({
                       scrollTop: target.offset().top -30
                    }, 500);
                    return false;
                }
            }
        });

        $('.carousel').on('slid.bs.carousel', function () {
            //console.log('slid event');
        });
        $('.carousel').on('slide.bs.carousel', function (e) {
            var bg = $(e.relatedTarget).attr("data-bg");
            if(bg) {
                $("#bg").css("background-image", 'url(' + bg + ')');
            }
        });
        $('.carousel .item.active').each(function() {
            var bg = $(this).attr("data-bg");
            if(bg) {
                $("#bg").css("background-image", 'url(' + bg + ')');
            }
        });

        $('.dropdown-multiselect').each(function() {
            $(this).multiselect({
                nonSelectedText: $(this).attr('placeholder')
            });
        });
      },
      finalize: function() {
        $('.dropdown-toggle').dropdown();
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.



function initMap() {
    jQuery(".map").each(function() {
        var myLatLng = {lat: parseFloat(jQuery(this).attr('data-latitude')), lng: parseFloat(jQuery(this).attr('data-longitude'))};

        var map = new google.maps.Map(jQuery(this).get(0), {
            zoom: 14,
            center: myLatLng
        });

        var marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            title: jQuery(this).attr('data-title')
        });
    });
}
